
import { defineComponent, ref, watch } from "vue";
import BreastfeedingBanner from "@/components/BreastfeedingBanner.vue";
import { getProduct } from "@/providers/products";
import { useRoute } from "vue-router";
import { i18n } from "@/i18n";
import InlineSvg from 'vue-inline-svg';

export default defineComponent({
  name: "Product",
  components: {
    "app-breastfeeding-banner": BreastfeedingBanner,
    "inline-svg": InlineSvg,
  },
  setup() {
    const { t } = i18n.global;
    const route = useRoute();
    const routeSlug = route.params["slug"];

    const currTab = ref("ingredients");
    const showZoomPackshot = ref(false);

    const product = ref(getProduct(String(routeSlug)));

    const shareProduct = () => {
      console.log(`shareProduct():`, product);
    };

    watch(i18n.global.locale, () => {
      product.value = getProduct(String(routeSlug));
    });

    return { t, product, currTab, showZoomPackshot, shareProduct };
  }
});
